interface RequestStatus {
  isError: boolean;
  isLoading: boolean;
  isOk: boolean;
}

interface ResultMethods<T, E> {
  map<R>(mapFn: (data: T) => R): ApiResult<R, E>;

  unwrapOr<U>(defaultValue: U): T | U;
}

interface OkResult<T> extends RequestStatus, ResultMethods<T, never> {
  data: T;
  isError: false;
  isLoading: false;
  isOk: true;
}

interface ErrorResult<E> extends RequestStatus, ResultMethods<never, E> {
  error: E;
  isError: true;
  isLoading: false;
  isOk: false;
}

interface LoadingResult extends RequestStatus, ResultMethods<never, never> {
  isError: false;
  isLoading: true;
  isOk: false;
}

interface IdleResult extends RequestStatus, ResultMethods<never, never> {
  isError: false;
  isLoading: false;
  isOk: false;
}

interface ApiResultConstructor {
  all(...results: RequestStatus[]): RequestStatus;

  error<E>(error: E): ErrorResult<E>;

  idle(): IdleResult;

  loading(): LoadingResult;

  ok<T>(data: T): OkResult<T>;

  some(...results: RequestStatus[]): RequestStatus;
}

const All = (results: RequestStatus[]): RequestStatus => ({
  get isError(): boolean {
    return results.every((result) => result.isError);
  },
  get isLoading(): boolean {
    return results.every((result) => result.isLoading);
  },
  get isOk(): boolean {
    return results.every((result) => result.isOk);
  },
});

const Some = (results: RequestStatus[]): RequestStatus => ({
  get isError(): boolean {
    return results.some((result) => result.isError);
  },
  get isLoading(): boolean {
    return results.some((result) => result.isLoading);
  },
  get isOk(): boolean {
    return results.some((result) => result.isOk);
  },
});

export const ApiResult: ApiResultConstructor = {
  all: function (...results: RequestStatus[]): RequestStatus {
    return All(results);
  },
  error<E>(error: E): ErrorResult<E> {
    const err: ErrorResult<E> = {
      error,
      isError: true,
      isLoading: false,
      isOk: false,
      map(): ErrorResult<E> {
        return err;
      },
      unwrapOr: function <U>(defaultValue: U): U {
        return defaultValue;
      },
    };
    return err;
  },
  idle(): IdleResult {
    const idle: IdleResult = {
      isError: false,
      isLoading: false,
      isOk: false,
      map<R>(): ApiResult<R, never> {
        return idle;
      },
      unwrapOr: function <U>(defaultValue: U): U {
        return defaultValue;
      },
    };
    return idle;
  },
  loading(): LoadingResult {
    const loading: LoadingResult = {
      isError: false,
      isLoading: true,
      isOk: false,
      map<R>(): ApiResult<R, never> {
        return loading;
      },
      unwrapOr: function <U>(defaultValue: U): U {
        return defaultValue;
      },
    };

    return loading;
  },
  ok<T>(data: T): OkResult<T> {
    const ok: OkResult<T> = {
      data,
      isError: false,
      isLoading: false,
      isOk: true,
      map<R>(mapFn: (data: T) => R): OkResult<R> {
        return ApiResult.ok(mapFn(data));
      },
      unwrapOr(): T {
        return data;
      },
    };

    return ok;
  },
  some(...results: RequestStatus[]): RequestStatus {
    return Some(results);
  },
};

export type ApiResult<T, E> =
  | OkResult<T>
  | ErrorResult<E>
  | LoadingResult
  | IdleResult;
