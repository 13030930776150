import { EnvironmentWithFedevAuth } from 'src/data-contract/schemas/environmentSchema';

export const environment: EnvironmentWithFedevAuth = {
  apiUrl: 'https://uvdm-test.eu-central-1.aws.cloud.bmw/uvr/pricing/api/v1',
  clientId: '40d46570-cfd7-4ec5-9060-2c5d217ccad6',
  issuer:
    'https://auth-i.bmwgroup.net:443/auth/oauth2/realms/root/realms/intranetb2x',
  name: 'development',
  production: false,
  quickSightPricingDashboardUrl:
    'https://eu-west-1.quicksight.aws.amazon.com/sn/embed/share/accounts/505199402599/dashboards/e923bb14-a2f9-4711-b902-e696ad2b397b?directory_alias=ucaa-final-test',
};
